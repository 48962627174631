import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import NotificationAlert from "../../../components/notifications/notification-alert";
import InvestorRelationsNavData from "../../../data/investor-relations/investor-relations-nav-data";
import Navigation from "../../../components/navigation/navigation";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import Icon from "../../../components/custom-widgets/icon";

const CorporateGovernance = () => {
  const title = "Corporate Governance";
  const description = "Corporate Governance Program policies at WaFd Bank";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-governance/hero-corporate-governance-040723-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-governance/hero-corporate-governance-040723-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-governance/hero-corporate-governance-040723-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-governance/hero-corporate-governance-040723-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-governance/hero-corporate-governance-040723-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-governance/hero-corporate-governance-040723-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/corporate-governance/hero-corporate-governance-040723-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations/corporate-governance"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-corporate-governance-040723.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "corporate-governance-hero",
    ...getHeroImgVariables(imgData),
    altText: "Female lawyer in front of the courthouse.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Accountability Built on Years of Integrity"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      active: true,
      title: "Corporate Governance"
    }
  ];

  const documentsData = [
    {
      id: "audit-committee-charter-pdf",
      text: "2024 Audit Committee Charter",
      href: "/documents/corporate-governance/2024/wafd-bank-audit-committee-charter.pdf"
    },
    {
      id: "code-of-ethics-pdf",
      text: "2024 Code of Ethics",
      href: "/documents/corporate-governance/2024/wafd-bank-code-of-ethics.pdf"
    },
    {
      id: "compensation-committee-charter-pdf",
      text: "2024 Compensation Committee Charter",
      href: "/documents/corporate-governance/2024/wafd-bank-compensation-committee-charter.pdf"
    },
    {
      id: "corporate-social-environmental-policy-pdf",
      text: "2024 Corporate Social Environmental Policy",
      href: "/documents/corporate-governance/2024/wafd-bank-corporate-social-environmental-policy.pdf"
    },
    {
      id: "executive-committee-charter-pdf",
      text: "2024 Executive Committee Charter",
      href: "/documents/corporate-governance/2024/wafd-bank-executive-committee-charter.pdf"
    },
    {
      id: "nominating-governance-committee-charter-pdf",
      text: "2024 Nominating Governance Committee Charter",
      href: "/documents/corporate-governance/2024/wafd-bank-nominating-and-governance-committee-charter.pdf"
    },
    {
      id: "risk-management-committee-charter-pdf",
      text: "2024 Risk Management Committee Charter",
      href: "/documents/corporate-governance/2024/wafd-bank-risk-management-committee-charter.pdf"
    },
    {
      id: "technology-committee-charter-pdf",
      text: "2024 Technology Committee Charter",
      href: "/documents/corporate-governance/2024/wafd-bank-technology-committee-charter.pdf"
    },
    {
      id: "trading-policy-pdf",
      text: "2024 Trading Policy",
      href: "/documents/corporate-governance/2024/wafd-bank-trading-policy.pdf"
    },
    {
      id: "whistleblower-policy-pdf",
      text: "2024 Whistleblower Policy",
      href: "/documents/corporate-governance/2024/wafd-bank-whistleblower-policy.pdf"
    }
  ];
  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...InvestorRelationsNavData} />
      <section className="container pb-0">
        <h1>Corporate Governance</h1>
        <h4>WaFd Bank implements the following policies as a part of our Corporate Governance Program:</h4>
        <ul>
          {documentsData.map((document, index) => (
            <li key={index}>
              <a className="text-decoration-none" id={document.id} href={document.href} target="_blank">
                <Icon name="file-pdf" class="mr-2" />
                {document.text}
              </a>
            </li>
          ))}
        </ul>
        <NotificationAlert
          type="primary"
          id="investor-info-notification-alert"
          bodyText='For more information about WaFd, Inc., our publicly traded holding company (Nasdaq: WAFD), or to request
              an investor kit, please email us at <a href="mailto:info@wafd.com" id="alert-info-mail-link">info@wafd.com</a> or call <a href="tel:206-626-8178" id="alert-wafd-headquarters-c3-tel-link">206-626-8178</a>'
        />
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CorporateGovernance;
